package online.interactiver.common.interactiveexercise

import online.interactiver.common.autogeneration.taskLineContent.*
import online.interactiver.common.autogeneration.taskLineContentFabric.*
import online.interactiver.common.enums.*
import online.interactiver.common.interactivepicture.InteractivePicture
import online.interactiver.common.interactivepicture.PhraseWord
import online.interactiver.common.interactivepicture.ShowCondition
import online.interactiver.common.math.chooseRandomByWeights
import online.interactiver.common.user.defaultLogo
import kotlin.random.Random

data class SliderAutoGenerationParams(
    val vocabularyToLearn: String = "", // words or collocations or phases to practice.
    var topic: String = "",
    var text: String = "",
    val contentType: EContentType = EContentType.VOCABULARY_WORDS,
    val targetLanguageLevel: String = "", // "Basic", "Intermediate", "Advanced"; otherwise ""
    val languageToLearn: ELanguage = ELanguage.ENGLISH,
    val interfaceLanguage: ELanguage = languageToLearn,
    val sameLanguageTask: Boolean = (languageToLearn == interfaceLanguage),
    val taskCount: Int? = 8, // will be adopted by count of words or GPT decision
    val isFinalStage: Boolean = true, // if false, sound and other details could be omitted to save generation time and money
    val withShuffling: Boolean = false, // if true then interactive elements will be shuffled
    val random: Random = Random(1),
    val logo: String? = defaultLogo,
    val linkInLogo: String? = "",
    val logoUseType: ELogoUseType = ELogoUseType.DEFAULT_LOGO,
    val gptModel: String = EGptType.OPEN_AI.type,
    val contentForm: EContentForm = EContentForm.CUSTOMER_SERVICE_DIALOGUE,
    val onTextSoundSave: suspend (String) -> Unit = {}
)

data class ExerciseContext (
    val numberInSlide: Int? = null // starting from 1
) {
    fun slideLess (n: Int) = (numberInSlide != null && numberInSlide < n)
}

enum class EExerciseType(
    val shortName: String,
    open val patterns: List<EExerciseUiPattern> = listOf(),
) {
    BUILD_SENTENCE("Build Sentence") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean {
            return true
        }

        override val patterns: List<EExerciseUiPattern> = listOf(
            EExerciseUiPattern.BUILD_SENTENCE_WHOLE,
            EExerciseUiPattern.BUILD_SENTENCE_PUZZLES,
            EExerciseUiPattern.BUILD_SENTENCE_SELECTORS,
            EExerciseUiPattern.BUILD_SENTENCE_INPUTS,
            EExerciseUiPattern.BUILD_SENTENCE_WHOLE_WITH_EXTRA_PUZZLES,
            EExerciseUiPattern.BUILD_SENTENCE_PUZZLES_WITH_EXTRA_PUZZLES
        )
    },
    CHOICE_IN_SENTENCE("Choice in Sentence") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean = true

        override val patterns: List<EExerciseUiPattern> = BUILD_SENTENCE.patterns
    },
    INPUTS_IN_SENTENCE("Inputs in Sentence") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean = true

        override val patterns: List<EExerciseUiPattern> = BUILD_SENTENCE.patterns
    },
    FILL_GAPS_IN_SENTENCE("Fill Gaps In Sentence") {
        override val patterns: List<EExerciseUiPattern> = BUILD_SENTENCE.patterns
    },
    WORD_MATCHING("Word Matching") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean {
            val words = exercise.split(";").map { it.trim() }
            if (words.size < 3) {
                return false
            }

            return words.all { word ->
                val parts = word.split("-").map { it.trim() }
                parts.size == 2 && parts[1].length < 45
            }
        }

        override val patterns = listOf(
            EExerciseUiPattern.FOUR_SHORT_PUZZLES,
            EExerciseUiPattern.FOUR_SHORT_SELECTORS,
            EExerciseUiPattern.FOUR_SHORT_INPUTS,
            EExerciseUiPattern.THREE_LONG_PUZZLES,
            EExerciseUiPattern.THREE_LONG_SELECTORS,
            EExerciseUiPattern.THREE_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS,
            EExerciseUiPattern.THREE_LONG_INPUTS,
            EExerciseUiPattern.THREE_BUTTONS_WITH_PICTURE,
            EExerciseUiPattern.FOUR_BUTTONS_WITH_PICTURE
        )    },
    WORDS_TRANSLATIONS("Word-Translation Matching") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean {
            val words = exercise.split(";").map { it.trim() }
            if (words.size < 3) {
                return false
            }

            return words.all { word ->
                val parts = word.split("-").map { it.trim() }
                parts.size == 2 && parts[1].length < 45
            }
        }

        override val patterns = listOf(
            EExerciseUiPattern.FOUR_SHORT_PUZZLES,
            EExerciseUiPattern.FOUR_SHORT_SELECTORS,
            EExerciseUiPattern.THREE_LONG_PUZZLES,
            EExerciseUiPattern.THREE_LONG_SELECTORS,
        )
    },
    WORDS_DEFINITIONS("Word-Definition Matching") {
        override fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean {
            val words = exercise.split(";").map { it.trim() }
            if (words.size < 3) {
                return false
            }

            return words.all { word ->
                val parts = word.split("-").map { it.trim() }
                parts.size == 2 && parts[1].length < 45
            }
        }

        override val patterns = listOf(
            EExerciseUiPattern.FOUR_SHORT_PUZZLES,
            EExerciseUiPattern.FOUR_SHORT_SELECTORS,
            EExerciseUiPattern.THREE_LONG_PUZZLES,
            EExerciseUiPattern.THREE_LONG_SELECTORS,
            EExerciseUiPattern.THREE_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS_WITH_PICTURE,
            EExerciseUiPattern.THREE_BUTTONS_WITH_PICTURE
        )
    },
    TEXT_TO_LEARN("Text to Learn") {
        override val patterns = listOf(
            EExerciseUiPattern.TEXT_AUDIO_INFO
        ) },
    TEXT_KEY_POINTS("Text Key Points") {
        override val patterns = listOf(
            EExerciseUiPattern.THREE_GAPS_IN_CORRECT_ORDER,
            EExerciseUiPattern.FOUR_GAPS_IN_CORRECT_ORDER
        ) },
    MARK_TRUE_FALSE("Mark True False") {
        override val patterns = listOf(
            EExerciseUiPattern.CHOOSE_THREE_CORRECT,
            EExerciseUiPattern.CHOOSE_FOUR_CORRECT
        ) },
    MARK_TRUE("Mark True"),
    CHOICE_ANSWER("Choice Answer") {
        override val patterns = listOf(
            EExerciseUiPattern.THREE_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS,
            EExerciseUiPattern.FOUR_BUTTONS_WITH_PICTURE,
            EExerciseUiPattern.THREE_BUTTONS_WITH_PICTURE
        )
    };

    fun getSampleName() = "'${shortName}'."
    open fun filterExercise(exercise: String, isTheSameLang: Boolean): Boolean = true

    fun isWordMatching(): Boolean = listOf(
        WORD_MATCHING,
        WORDS_DEFINITIONS,
        WORDS_TRANSLATIONS,
        CHOICE_ANSWER,
        MARK_TRUE_FALSE,
        TEXT_KEY_POINTS
    ).contains(this)

    fun isBuildSentence(): Boolean = listOf(
        BUILD_SENTENCE,
        CHOICE_IN_SENTENCE,
        INPUTS_IN_SENTENCE,
        FILL_GAPS_IN_SENTENCE,
        TEXT_TO_LEARN
    ).contains(this)

    companion object {
        fun fromShortName(shortName: String): EExerciseType? {
            return entries.find { it.shortName == shortName }
        }

        fun fromRawTaskLine(taskLine: String?): EExerciseType? {
            if (taskLine == null) return null

            val taskLineWithoutNum = if (taskLine.first().isDigit()) {
                taskLine.substringAfter(".").trim()
            } else {
                taskLine
            }
            val rawType = taskLineWithoutNum
                .substringBefore(".").trim()
                .removePrefix("\'")
                .removeSuffix("\'")
            return fromShortName(rawType)
        }
    }
}

val maxTopicLength = 80

val fullScreenLength = 180       // max: 7 lines of 30 symbols
val screenLengthWithTranslation = 140 // max: 5 lines of 30 symbol
val lineLength = 30       // max: 7 lines of 30 symbols

enum class EExerciseUiPattern(
    val shortName: String,
    val uiLabel: String,
    val uiTooltip: String? = null,
    val taskLineContentFabric: TaskLineContentFabric<*>,
) {
    FOUR_SHORT_PUZZLES(
        "short_puzzles",
        "4 puzzles",
        taskLineContentFabric = WordMatchingContentFabric(4),
    ) {
        override val maxWordLength: Int = 36
        override val maxMatchLength: Int = 36

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) {
            val hasTooLongWord = it.wordMatchPairs.any { it.word.length > maxWordLength || it.match.length > maxMatchLength }
            if (hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

            return@weightOfSuitabilityBuilder 5.5
        }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToWordMatchingContentByMatchingWords(content, slide)
    },

    FOUR_SHORT_SELECTORS(
        "short_selectors",
        "4 selectors",
        taskLineContentFabric = WordMatchingContentFabric(4),
    ) {
        override val maxWordLength: Int = 15
        override val maxMatchLength: Int = 35

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) {
            val hasTooLongWord = it.wordMatchPairs.any { it.word.length > maxWordLength || it.match.length > maxMatchLength }
            if (hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

            return@weightOfSuitabilityBuilder 5.5
        }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToWordMatchingContentByMatchingWords(content, slide)
    },

    FOUR_SHORT_INPUTS(
        "short_inputs",
        "4 inputs",
        taskLineContentFabric = WordMatchingContentFabric(4)
    ) {
        override val maxWordLength: Int = 15
        override val maxMatchLength: Int = 35

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) {
                val hasTooLongWord = it.wordMatchPairs.any { it.word.length > maxWordLength || it.match.length > maxMatchLength }
                if (hasTooLongWord || exerciseContext?.slideLess(4) == true) return@weightOfSuitabilityBuilder 0.0

                return@weightOfSuitabilityBuilder 5.0
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToWordMatchingContentByMatchingWords(content, slide)
    },

    THREE_LONG_PUZZLES(
        "long_puzzles",
        "3 puzzles",
        taskLineContentFabric = WordMatchingContentFabric(3),
    ) {
        override val maxWordLength: Int = 60
        override val maxMatchLength: Int = 60

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) { 1.5 }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): TaskLineContent {
            val newContent = content as WordMatchingContent

            newContent.wordMatchPairs.forEach {
                slide.gapPuzzles?.find { puzzle ->
                    puzzle.visibleElement.text?.simpleText == it.word
                }?.sound?.src.let { soundSrc ->
                    it.soundSrc = soundSrc
                }
            }

            return newContent
        }
    },

    THREE_LONG_SELECTORS(
        "long_selectors",
        "3 selectors",
        taskLineContentFabric = WordMatchingContentFabric(3),
    ) {
        override val maxWordLength: Int = 35
        override val maxMatchLength: Int = 60

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double
        = weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) { 1.5 }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): WordMatchingContent {
            val newContent = content as WordMatchingContent

            newContent.wordMatchPairs.forEachIndexed { index, wordMatch ->
                slide.figuresAndLines?.find { it.identifier.code == "sound${index + 1}" }?.sound?.src?.let {
                    wordMatch.soundSrc = it
                }
            }
            return newContent
        }
    },

    THREE_LONG_INPUTS(
        "long_inputs",
        "3 inputs",
        taskLineContentFabric = WordMatchingContentFabric(3)
    ) {
        override val maxWordLength: Int = 35
        override val maxMatchLength: Int = 60

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<WordMatchingContent>(taskContent, isOpenAiFormat) {
                val hasTooLongWord = it.wordMatchPairs.any { it.word.length > maxWordLength || it.match.length > maxMatchLength }
                if (hasTooLongWord || exerciseContext?.slideLess(4) == true) return@weightOfSuitabilityBuilder 0.0

                return@weightOfSuitabilityBuilder 1.0
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): TaskLineContent {
            val newContent = content as WordMatchingContent

            newContent.wordMatchPairs.forEachIndexed { index, wordMatch ->
                slide.figuresAndLines?.find { it.identifier.code == "sound${index + 1}" }?.sound?.src?.let {
                    wordMatch.soundSrc = it
                }
            }
            return newContent
        }
    },

    THREE_BUTTONS(
        "long_buttons",
        "3 buttons",
        taskLineContentFabric = QuestionWithAnswersContentFabric(3),
    ) {
        override val maxWordLength: Int = 75
        override val maxMatchLength: Int = 100

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<QuestionWithAnswersContent>(taskContent, isOpenAiFormat) {
                val hasTooLongQuestion = it.question.length > maxMatchLength
                val hasTooLongWord = it.answers.any { it.value.length > maxWordLength }
                if (hasTooLongQuestion || hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

            return@weightOfSuitabilityBuilder 2.0
        }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): QuestionWithAnswersContent {
            val newContent = content as QuestionWithAnswersContent

            newContent.answers.forEach {
                slide.controls?.find { puzzle ->
                    puzzle.visibleElement.text?.simpleText == it.value
                }?.sound?.src.let { soundSrc ->
                    it.soundSrc = soundSrc
                }
            }

            return newContent
        }
    },
    FOUR_BUTTONS(
        "short_buttons",
        "4 buttons",
        taskLineContentFabric = QuestionWithAnswersContentFabric(4),
    ) {
        override val maxWordLength: Int = 40
        override val maxMatchLength: Int = 60

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<QuestionWithAnswersContent>(taskContent, isOpenAiFormat) {
                val hasTooLongQuestion = it.question.length > maxMatchLength
                val hasTooLongWord = it.answers.any { it.value.length > maxWordLength }
                if (hasTooLongQuestion || hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

                return@weightOfSuitabilityBuilder 2.0
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): QuestionWithAnswersContent {
            val newContent = content as QuestionWithAnswersContent

            newContent.answers.forEach {
                slide.controls?.find { puzzle ->
                    puzzle.visibleElement.text?.simpleText == it.value
                }?.sound?.src.let { soundSrc ->
                    it.soundSrc = soundSrc
                }
            }

            return newContent
        }
    },
    BUILD_SENTENCE_PUZZLES_WITH_EXTRA_PUZZLES(
        "puzzles_with_extra",
        "puzzle gaps with extra words",
        "Insert the missing words from the list with extra words from [brackets].",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation

                if (it.getFinalOptions().size <= 0) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                val originalSentenceLen = it.getCorrectPhrase().length
                val puzzleLen = it.getFinalOptions().maxOf { it.value.length }
                val puzzlesLen = puzzleLen * it.getFinalOptions().size
                val wordReplacedByPuzzlesLen = it.getFinalOptions().sumOf { it.value.length }
                val extraPuzzlesLen = it.getFinalOptions().sumOf { it.finalOptions.filter { it.isCorrect != "1" }
                        .sumOf { it.value.length } }
                val lengthOnScreen = originalSentenceLen + puzzlesLen * 2 - wordReplacedByPuzzlesLen + extraPuzzlesLen

                val hasTooLongPhrase = lengthOnScreen > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                val numberOfPuzzlesInLine = lineLength / puzzleLen
                if (numberOfPuzzlesInLine < 3) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.01
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },
    BUILD_SENTENCE_WHOLE_WITH_EXTRA_PUZZLES(
        "whole_with_extra",
        "whole sentence with extra words",
        "Complete the sentence using the given words and the extra from [brackets].",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation
                val originalSentenceLen = it.getCorrectPhrase().length
                val extraPuzzlesLen = it.getFinalOptions().sumOf { it.finalOptions.filter { it.isCorrect != "1" }
                    .sumOf { it.value.length } }
                val lengthOnScreen = originalSentenceLen * 2 + extraPuzzlesLen

                val hasTooLongPhrase = lengthOnScreen > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.01
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },
    BUILD_SENTENCE_WHOLE(
        "whole",
        "whole sentence",
        "Tape the sentence using the given words.",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation / 2
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength / 2

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation
                val hasTooLongPhrase = it.getCorrectPhrase().length > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.5
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },

    BUILD_SENTENCE_PUZZLES(
        "puzzles",
        "puzzle gaps",
        "Insert the missing words from the list (no extra words).",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation

                if (it.getFinalOptions().size < 2) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                val originalSentenceLen = it.getCorrectPhrase().length
                val puzzlesLen = (it.getFinalOptions().maxOf { it.value.length }) * it.getFinalOptions().size
                val wordReplacedByPuzzlesLen = it.getFinalOptions().sumOf { it.value.length }
                val lengthOnScreen = originalSentenceLen + puzzlesLen * 2 - wordReplacedByPuzzlesLen

                val hasTooLongPhrase = lengthOnScreen > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.7
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },

    BUILD_SENTENCE_SELECTORS(
        "choice",
        "choice gaps",
        "Choose the missing words from the dropdown.",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation

                if (it.getFinalOptions().size < 1) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                val selectorIconLen = 5
                val originalSentenceLen = it.getCorrectPhrase().length
                val selectorsLen = (it.getFinalOptions().maxOf { it.value.length } + selectorIconLen) * it.getFinalOptions().size
                val wordReplacedBySelectorsLen = it.getFinalOptions().sumOf { it.value.length }
                val lengthOnScreen = originalSentenceLen + selectorsLen - wordReplacedBySelectorsLen

                val hasTooLongPhrase = lengthOnScreen > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.6
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },

    BUILD_SENTENCE_INPUTS(
        "inputs",
        "input gaps",
        "Type the missing words. Correct options are taken from [brackets], marked with *",
        taskLineContentFabric = BuildSentenceContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<BuildSentenceContent>(taskContent, isOpenAiFormat) {
                if (it.getFinalOptions().size < 1 || exerciseContext?.slideLess(4) == true) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                val maxPhraseLen = if (it.hasHint()) maxPhraseLengthWithTranslation else maxPhraseLengthWithoutTranslation
                val hasTooLongPhrase = it.getCorrectPhrase().length > maxPhraseLen
                if (hasTooLongPhrase) {
                    return@weightOfSuitabilityBuilder 0.0
                }

                return@weightOfSuitabilityBuilder 0.4
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToBuildSentenceContent(content, slide)
    },
    THREE_GAPS_IN_CORRECT_ORDER(
        "three_correct_order",
        "3 correct order",
        "Put the numbers in correct order",
        taskLineContentFabric = TextKeyPointsContentFabric(3)
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<TextKeyPointsContent>(taskContent, isOpenAiFormat) {
                return@weightOfSuitabilityBuilder 0.5
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToTextKeyPointsContent(content, slide)
      },
    FOUR_GAPS_IN_CORRECT_ORDER(
        "four_correct_order",
        "4 correct order",
        "Put the numbers in correct order",
        taskLineContentFabric = TextKeyPointsContentFabric(4)
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<TextKeyPointsContent>(taskContent, isOpenAiFormat) {

                return@weightOfSuitabilityBuilder 0.5
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToTextKeyPointsContent(content, slide)
    },
    CHOOSE_THREE_CORRECT(
        "choose_3_correct",
        "choose 3 correct",
        "",
        taskLineContentFabric = MarkTrueFalseContentFabric(3)
    ) {
        override fun defaultMetaTags(): List<EExerciseUiPatternMetaTag> {
            return listOf(EExerciseUiPatternMetaTag.NO_RADIO)
        }
        override fun weightOfSuitability(
            taskContent: String,
            isOpenAiFormat: Boolean,
            exerciseContext: ExerciseContext?
        ): Double =
            weightOfSuitabilityBuilder<MarkTrueFalseContent>(taskContent, isOpenAiFormat) {
                return@weightOfSuitabilityBuilder 0.5
            }


        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToMarkTrueFalseContent(content, slide)
    },
    CHOOSE_FOUR_CORRECT(
      "choose_4_correct",
        "choose 4 correct",
        "",
        taskLineContentFabric = MarkTrueFalseContentFabric(4)
    ) {
        override fun defaultMetaTags(): List<EExerciseUiPatternMetaTag> {
            return listOf(EExerciseUiPatternMetaTag.NO_RADIO)
        }
        override fun weightOfSuitability(
            taskContent: String,
            isOpenAiFormat: Boolean,
            exerciseContext: ExerciseContext?
        ): Double =
            weightOfSuitabilityBuilder<MarkTrueFalseContent>(taskContent, isOpenAiFormat) {
                return@weightOfSuitabilityBuilder 0.5
            }


        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToMarkTrueFalseContent(content, slide)
    },
    THREE_BUTTONS_WITH_PICTURE(
        "long_buttons_picture",
        "3 buttons with picture",
        taskLineContentFabric = QuestionWithAnswersContentFabric(3),
    ) {
        override val maxWordLength: Int = 75
        override val maxMatchLength: Int = 100

        override fun defaultMetaTags(): List<EExerciseUiPatternMetaTag> {
            return listOf(EExerciseUiPatternMetaTag.NO_RADIO)
        }

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<QuestionWithAnswersContent>(taskContent, isOpenAiFormat) {
                if (it.picture == null) return@weightOfSuitabilityBuilder 0.0

                val hasTooLongQuestion = it.question.length > maxMatchLength
                val hasTooLongWord = it.answers.any { it.value.length > maxWordLength }
                if (hasTooLongQuestion || hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

                return@weightOfSuitabilityBuilder 2.0
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): QuestionWithAnswersContent {
            val newContent = content as QuestionWithAnswersContent

            newContent.answers.forEach {
                slide.controls?.find { puzzle ->
                    puzzle.visibleElement.text?.simpleText == it.value
                }?.sound?.src.let { soundSrc ->
                    it.soundSrc = soundSrc
                }
            }

            return newContent
        }
    },
    FOUR_BUTTONS_WITH_PICTURE(
        "short_buttons_picture",
        "4 buttons with picture",
        taskLineContentFabric = QuestionWithAnswersContentFabric(4),
    ) {
        override val maxWordLength: Int = 40
        override val maxMatchLength: Int = 60

        override fun defaultMetaTags(): List<EExerciseUiPatternMetaTag> {
            return listOf(EExerciseUiPatternMetaTag.NO_RADIO)
        }

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<QuestionWithAnswersContent>(taskContent, isOpenAiFormat) {
                if (it.picture == null) return@weightOfSuitabilityBuilder 0.0

                val hasTooLongQuestion = it.question.length > maxMatchLength
                val hasTooLongWord = it.answers.any { it.value.length > maxWordLength }
                if (hasTooLongQuestion || hasTooLongWord) return@weightOfSuitabilityBuilder 0.0

                return@weightOfSuitabilityBuilder 2.0
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): QuestionWithAnswersContent {
            val newContent = content as QuestionWithAnswersContent

            newContent.answers.forEach {
                slide.controls?.find { puzzle ->
                    puzzle.visibleElement.text?.simpleText == it.value
                }?.sound?.src.let { soundSrc ->
                    it.soundSrc = soundSrc
                }
            }

            return newContent
        }
    },

    TEXT_AUDIO_INFO(
        "text",
        "text",
        "Read the text and check yourself",
        taskLineContentFabric = TextToLearnContentFabric
    ) {
        override val maxPhraseLengthWithTranslation: Int = screenLengthWithTranslation
        override val maxPhraseLengthWithoutTranslation: Int = fullScreenLength

        override fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double =
            weightOfSuitabilityBuilder<TextToLearnContent>(taskContent, isOpenAiFormat) {
                return@weightOfSuitabilityBuilder 0.5
            }

        override fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture) =
            injectSoundFromSlideToTextToLearnContent(content, slide)
    };

    fun <T: TaskLineContent> weightOfSuitabilityBuilder(rawContent: String, isOpenAiFormat: Boolean, weight: (T) -> Double): Double {
        if (!this.taskLineContentFabric.canBeBuiltFrom(rawContent, isOpenAiFormat)) return 0.0
        val content: T? = this.taskLineContentFabric.fromRawTaskLine(rawContent, isOpenAiFormat) as? T
        return content?.let { weight(it) } ?: 0.0
    }

    abstract fun weightOfSuitability(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Double
    abstract fun injectSoundFromSlideToContent(content: TaskLineContent, slide: InteractivePicture): TaskLineContent

    fun canBeBuiltFrom(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Boolean {
        //temporary solution to avoid getting raw exercise type after changing pattern
        if (!isOpenAiFormat) return true
        return weightOfSuitability(taskContent, isOpenAiFormat, exerciseContext) > 0.00001
    }

    open fun isSuitableFor(exerciseType: EExerciseType): Boolean = exerciseType.patterns.contains(this)

    open fun defaultMetaTags() = listOf<EExerciseUiPatternMetaTag>()

    open val maxWordLength: Int? = null
    open val maxMatchLength: Int? = null

    open val maxPhraseLengthWithTranslation: Int? = null
    open val maxPhraseLengthWithoutTranslation: Int? = null
    open val maxTranslationLength: Int? = null

    fun getSampleName(): String = "'${shortName}'"

    class Error {
        class NoSuchPatternFound(rawMetaTag: String): Exception("Exercise UI pattern '$rawMetaTag' not found")
    }

    companion object {

        private fun injectSoundFromSlideToTextKeyPointsContent(content: TaskLineContent, slide: InteractivePicture): TextKeyPointsContent {
            val newContent = content as TextKeyPointsContent

            slide.figuresAndLines?.find { it.identifier.code == "sound" }?.sound?.src?.let { newContent.soundSrc = it }

            return newContent
        }

        private fun injectSoundFromSlideToTextToLearnContent(content: TaskLineContent, slide: InteractivePicture): TextToLearnContent {
            val newContent = content as TextToLearnContent

            slide.figuresAndLines?.find { it.identifier.code == "sound" }?.sound?.src?.let { newContent.soundSrc = it }

            return newContent
        }
        private fun injectSoundFromSlideToMarkTrueFalseContent(content: TaskLineContent, slide: InteractivePicture): MarkTrueFalseContent {
            val newContent = content as MarkTrueFalseContent

            slide.figuresAndLines?.find { it.identifier.code == "sound" }?.sound?.src?.let { newContent.soundSrc = it }

            return newContent
        }
        private fun injectSoundFromSlideToBuildSentenceContent(content: TaskLineContent, slide: InteractivePicture): BuildSentenceContent {
            val newContent = content as BuildSentenceContent

            slide.figuresAndLines?.find { it.identifier.code == "sound" }?.sound?.src?.let { newContent.soundSrc = it }

            return newContent
        }
        private fun injectSoundFromSlideToWordMatchingContentByMatchingWords(
            content: TaskLineContent, slide: InteractivePicture
        ): WordMatchingContent {
            val newContent = content as WordMatchingContent

            newContent.wordMatchPairs.forEachIndexed { index, value ->
                var foundAnySound = false

                slide.figuresAndLines?.find { figure ->
                    figure.visibleElement.text?.simpleText == value.match
                }?.sound?.src?.let { soundSrc ->
                    foundAnySound = true
                    value.soundSrc = soundSrc
                }

                if (!foundAnySound) {
                    slide.figuresAndLines?.find { figure ->
                        figure.identifier.code == "sound${index + 1}"
                    }?.sound?.src?.let { soundSrc ->
                        value.soundSrc = soundSrc
                    }
                }
            }

            return content
        }
        private fun findPatterns(predicate: (EExerciseUiPattern) -> Boolean): List<EExerciseUiPattern> {
            return entries.filter(predicate)
        }
        private fun findPattern(predicate: (EExerciseUiPattern) -> Boolean): EExerciseUiPattern? {
            val suitablePatterns = findPatterns(predicate)
            return if (suitablePatterns.isNotEmpty()) {
                suitablePatterns.random()
            } else null
        }
        fun fromShortName(shortName: String): EExerciseUiPattern? = findPattern { it.shortName == shortName }
        fun anySuitableFor(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext): EExerciseUiPattern? = findPattern { it.canBeBuiltFrom(taskContent, isOpenAiFormat, exerciseContext) }
        fun anySuitableByWeights(type: EExerciseType, taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): EExerciseUiPattern? {
            val suitablePatterns = findPatterns { it.isSuitableFor(type) && it.canBeBuiltFrom(taskContent, isOpenAiFormat, exerciseContext) }
            return chooseRandomByWeights(
                suitablePatterns,
                suitablePatterns.map { it.weightOfSuitability(taskContent, isOpenAiFormat, exerciseContext) },
                Random(taskContent.hashCode())
            )
        }
    }
}

enum class EExerciseUiPatternMetaTag(
    val shortName: String,
    val uiLabel: String
) {
    NO_RADIO("no_radio", "multiple answers"),

    NO_SOUND("no_sound", "only text"),
    SOUND_AFTER_CHECK("sound_after_check", "after check"),
    NO_TEXT("no_text", "only sound");

    class Error {
        class NoSuchTagFound(rawMetaTag: String): Exception("Exercise UI pattern meta tag '$rawMetaTag' not found")
    }

    companion object {
        fun fromShortName(shortName: String): EExerciseUiPatternMetaTag? = entries.find { it.shortName == shortName }
    }
}

class ExerciseUiPattern(
    val type: EExerciseUiPattern,
    val metaTags: List<EExerciseUiPatternMetaTag> = listOf()
) {
    fun toRawExerciseUiPattern(): String = "${type.shortName}${if (metaTags.isNotEmpty()) metaTags.joinToString("__", prefix = "__") { it.shortName } else ""}"

    val taskLineContentFabric: TaskLineContentFabric<*>
        get() = type.taskLineContentFabric

    fun canBeBuiltFrom(taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): Boolean =
        type.canBeBuiltFrom(taskContent, isOpenAiFormat, exerciseContext)

    fun isSuitableFor(exerciseType: EExerciseType): Boolean = type.isSuitableFor(exerciseType)

    companion object {
        fun fromRawExerciseUiPattern(rawPattern: String): ExerciseUiPattern {
            val splitted = rawPattern.split("__")
            val type = EExerciseUiPattern.fromShortName(splitted.first()) ?:
                throw EExerciseUiPattern.Error.NoSuchPatternFound(splitted.first())
            val tags = mutableListOf<EExerciseUiPatternMetaTag>()
            for (i in 1 .. splitted.lastIndex) {
                val tag = EExerciseUiPatternMetaTag.fromShortName(splitted[i]) ?:
                    throw EExerciseUiPatternMetaTag.Error.NoSuchTagFound(splitted[i])
                tags.add(tag)
            }
            return ExerciseUiPattern(type, tags.toList())
        }

        fun anySuitableByWeights(type: EExerciseType, taskContent: String, isOpenAiFormat: Boolean, exerciseContext: ExerciseContext?): ExerciseUiPattern? =
            EExerciseUiPattern.anySuitableByWeights(type, taskContent, isOpenAiFormat, exerciseContext)?.let {
                ExerciseUiPattern(
                    it,
                    metaTags = it.defaultMetaTags()
                )
            }
    }
}

enum class SoundGenerationType {
    NONE,
    SENTENCE_CONTINUOUS,
    SENTENCE_BY_WORDS,
    WORD_ONLY,
    MATCH_ONLY,
    WORD_AND_MATCH
}

data class SoundSetting(
    val googleSound: SoundGenerationType = SoundGenerationType.NONE,
    val cloudFilePath: String? = null,
    val altCloudFilePath: String? = cloudFilePath,
    val showCondition: ShowCondition? = null // пока не реализуем
)

data class ExerciseBuildSentence(
    val wordCombinationsInCorrectOrder: MutableList<PhraseWord>,
    val sentenceToSound: String? = null,
    val translation: String? = null,
    val soundSetting: SoundSetting? = SoundSetting(googleSound = SoundGenerationType.SENTENCE_CONTINUOUS),
    var vocabularyToLearn: List<ExerciseVocabularyToLearn> = listOf(),
    val possibleTypes: MutableSet<EExerciseMechanicsType> = EExerciseMechanicsType.entries.toMutableSet(),
    val requestParams: SliderAutoGenerationParams,
    val taskNumber: Int = 1
)

data class VocabularyToLearnSelectorOption(
    val value: String,
    val isCorrect: String
)

data class ExerciseVocabularyToLearn(
    val value: String,
    val partOfWord: EWordParticle = EWordParticle.NONE,
    val isManual: Boolean = false,
    var usageCount: Int = 0,

    val wordDef: String = "", // definition or translation, got from matching exercises
    val soundSetting: SoundSetting? = SoundSetting(googleSound = SoundGenerationType.WORD_ONLY),
    val otherOptions: MutableList<String> = mutableListOf(), // on language to learn for select tasks
    val finalOptions: MutableList<VocabularyToLearnSelectorOption> = mutableListOf()
)

data class ExerciseFillGapsInSentence(
    val wordCombinationsInCorrectOrder: MutableList<PhraseWord>,
    val sentenceToSound: String? = null,
    val translation: String? = null,
    val soundSetting: SoundSetting? = SoundSetting(googleSound = SoundGenerationType.SENTENCE_CONTINUOUS),
    val languageToLearn: ELanguage,
    val interfaceLanguage: ELanguage,
)

data class ExerciseWordMatching(
    val wordMatches: MutableList<ExerciseVocabularyToLearn>,
    val possibleTypes: MutableSet<EExerciseMechanicsType> = EExerciseMechanicsType.entries.toMutableSet(),
    val requestParams: SliderAutoGenerationParams,
)

enum class EExerciseMechanicsType(val value: String) {
    PUZZLES("puzzles"),
    DROPDOWNS("dropdowns"),
    INPUTS("inputs"),
    BUTTONS("buttons"),
    WORD_COMBINATIONS("word_combinations"),
}
