package pages.nativeLanguageChoice.ui

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import pages.nativeLanguageChoice.updateNativeLanguageRequest
import pages.studentDuo.SetStudentDuoInterfaceLanguage
import pages.studentDuo.selectStudentDuoUserProfile
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffect
import react.useState
import shared.components.ProtectedRoute.ProtectedRoute
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage
import widgets.LanguageAutoContent.ui.generateButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams
import widgets.UserProfile.SetUserProfile


val NativeLanguageChoice = VFC {
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    val (nativeLanguage, setNativeLanguage) = useState(userProfile?.nativeLanguage ?: ELanguage.ENGLISH.text)

    useEffect(userProfile?.nativeLanguage) {
        if (userProfile?.nativeLanguage == null) {
            return@useEffect
        }

        setNativeLanguage(userProfile.nativeLanguage)
    }

    ProtectedRoute {
        toStudentDuo = true
        if (userProfile == null) {
            return@ProtectedRoute
        }

        section {
            css(page)
            SolvedTasks {}
            div {
                css(container)
                SelectLanguage {
                    label = "Choose your native language"
                    value = nativeLanguage
                    onChange = {
                        setNativeLanguage(it)
                    }
                }
                Button {
                    css(generateButton)
                    onClick = {
                        GlobalScope.launch {
                            val savedLanguage = updateNativeLanguageRequest(nativeLanguage)
                            if (savedLanguage == null) {
                                dispatch(OpenErrorModal("Error saving language"))
                                return@launch
                            }
                            dispatch(SetStudentDuoInterfaceLanguage(savedLanguage))
                            dispatch(SetUserProfile(userProfile.copy(nativeLanguage = savedLanguage)))
                            navigate("/duo/content/learning-language")
                        }
                    }
                    +"Continue"
                }
            }
        }
    }
}