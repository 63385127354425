package utils.localization

import online.interactiver.common.enums.ELanguage

class Localization {
    companion object {
        fun getTopicPlaceholder(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Мы советуем описать 3-5 словами тему, которую вы хотите попрактиковать. Например: \"Заказ кофе в кафе\" или \"Глаголы в прошедшем времени\""
            } else {
                "We recommend describing the topic you want to practice in 3-5 words. For example: \"Ordering coffee in a café\" or \"Past tense verbs in English.\""
            }
        }
        fun getGenerateButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Запустить!"
            } else {
                "Do the magic!"
            }
        }
        fun getTopicTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Введите тему, слова или текст на любом языке"
            } else {
                "Write a topic, words, or text in any language"
            }
        }
        fun getLanguageToLearnTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Выберите язык для изучения"
            } else {
                "Choose a language you want to learn"
            }
        }
        fun getLanguageLevelTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Выберите уровень владения языком"
            } else {
                "Select your language level"
            }
        }
        fun getContinueButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Продолжить"
            } else {
                "Continue"
            }
        }
        fun getBackButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Назад"
            } else {
                "Back"
            }
        }
        fun getChooseTopicTitle(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Выберите тему для практики"
            } else {
                "What topic do you want to practice?"
            }
        }
        fun getOwnTopicButtonText(nativeLanguage: String): String {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                "Я хочу создать задания по моей теме"
            } else {
                "I want to create exercises on my own topic"
            }
        }

        fun getTopics(nativeLanguage: String): List<String> {
            return if (nativeLanguage == ELanguage.RUSSIAN.text) {
                listOf(
                    "Разговор с соседями",
                    "Визит к врачу",
                    "Глаголы для уровня B1",
                    "Фильм: \"Властелин колец\""
                )
            } else {
                listOf(
                    "Chat with the neighbors",
                    "Visit to the doctor",
                    "Verbs for B1 level",
                    "Movie: \"The Lord of the Rings\""
                )
            }
        }
    }
}