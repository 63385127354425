package widgets.DuoCourses.ui.DuoCourses

import antd.Button
import csstype.ClassName
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import pages.studentDuo.selectStudentDuoCourses
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.key
import react.redux.useSelector
import react.router.useNavigate
import widgets.DuoCourses.ui.DuoCourse.DuoCourse
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

external interface DuoCoursesProps : Props {
    var close: () -> Unit
}

val DuoCourses = FC<DuoCoursesProps> { props ->
    val courses = useSelector(selectStudentDuoCourses)
    val navigate = useNavigate()

    div {
        css(container)
        div {
            css(classNames = arrayOf(ClassName("scroll")), coursesStyle)
            if (courses.isEmpty()) {
                p {
                    css(noCourses)
                    +"No courses"
                }
            } else {
                courses.forEach {
                    DuoCourse {
                        key = it.id.toString()
                        course = it
                        onDelete = {
                            localStorage.setItem(
                                "duo_courses",
                                Json.encodeToString(courses.filter { course -> it.id != course.id })
                            )
                        }
                        close = props.close
                    }
                }
            }
        }
        div {
            css(buttonsContainer)
            Button {
                css(newTaskButton)
                onClick = {
                    navigate("/duo/content")
                }
                +"Study other topic"
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate("/duo/profile-settings")
                }
                +"Profile and settings"
            }
        }
    }
}