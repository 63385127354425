package online.interactiver.common.course

import kotlinx.serialization.Serializable
import online.interactiver.common.enums.*
import online.interactiver.common.math.predefinedRandom
import kotlin.random.Random

const val defaultNumberOfSlidesInSlider = 10
const val minNumberOfSlidesInSlider = 6
const val maxNumberOfSlidesInSlider = 25

const val defaultMinNumberOfSlidersInCourse = 8
const val minMinNumberOfSlidersInCourse = 1
const val maxMinNumberOfSlidersInCourse = 20

@Serializable
data class CourseState(
    var id: Int? = null,
    var interfaceLanguage: String = ELanguage.RUSSIAN.text,
    var languageToLearn: String = ELanguage.ENGLISH.text,
    var languageLevel: String = ELanguageLevel.ANY.value,
    var startFromSentences: Boolean = true,
    var courseLevel: String = ECourseLevel.REPEAT.value,
    var enableGamifiedPopups: Boolean = true,
    var continueButtonLink: String = "",
    var continueButtonText: String = "",
    var numberOfSlidesInSlider: Int = defaultNumberOfSlidesInSlider,
    var sentencesToGenerateExercises: String = "",
    var wordsToGenerateExercises: String = "",
    var name: String = "Course",
    var courseGenerationType: String = ECourseGenerationType.WORDS_SENTENCES.value,
    var text: String = "",
    var parentFolderId: Int? = null,
    var minNumberOfSliders: Int = defaultMinNumberOfSlidersInCourse,
    var sameLanguage: Boolean = interfaceLanguage == languageToLearn,
    val contentForm: EContentForm = EContentForm.CUSTOMER_SERVICE_DIALOGUE
)
