package widgets.CourseSettings.ui.components.LanguagesSettings

import antd.Input
import app.useAppDispatch
import csstype.Auto
import csstype.px
import emotion.react.css
import kotlinx.browser.localStorage
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import shared.components.header.Header
import shared.components.selectWithLabel.SelectWithLabel
import utils.types.extend
import widgets.CourseSettings.*
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage

enum class ELanguageSettingsLocalStorageKeys(val value: String) {
    LANGUAGE_TO_LEARN("course_language_to_learn"),
    INTERFACE_LANGUAGE("course_interface_language"),
    LANGUAGE_LEVEL("course_language_level")
}

val LanguagesSettings = FC<Props> {
    val languageLevel = useSelector(selectCourseLanguageLevel)
    val interfaceLanguage = useSelector(selectCourseInterfaceLanguage)
    val languageToLearn = useSelector(selectCourseLanguageToLearn)
    val courseName = useSelector(selectCourseName)

    val dispatch = useAppDispatch()

    useLanguageSettingsFromLocalStorageEffect()

    div {
        css(name)
        Header {
            fontSize = 14.0.px
            lineHeight = 18.9.px
            text = "Course name"
        }
        Input {
            value = courseName
            onInput = {
                dispatch(SetCourseName(it.currentTarget.value))
            }
            placeholder = "Type name"
        }
    }

    div {
        css(horizontalContainer)
        div {
            css(select)
            SelectLanguage {
                label = "Language to learn"
                value = languageToLearn
                onChange = {
                    localStorage.setItem(ELanguageSettingsLocalStorageKeys.LANGUAGE_TO_LEARN.value, it)
                    dispatch(SetCourseLanguageToLearn(it))
                }
            }
        }
        div {
            css(select)
            SelectWithLabel {
                label = "Language level"
                value = languageLevel
                options = ELanguageLevel.entries.map { it.value }
                onChange = {
                    localStorage.setItem(ELanguageSettingsLocalStorageKeys.LANGUAGE_LEVEL.value, it)
                    dispatch(SetCourseLanguageLevel(it))
                }
            }
        }
        div {
            css(select.extend {
                marginLeft = Auto.auto
            })
            SelectLanguage {
                label = "Interface language"
                value = interfaceLanguage
                onChange = {
                    localStorage.setItem(ELanguageSettingsLocalStorageKeys.INTERFACE_LANGUAGE.value, it)
                    dispatch(SetCourseInterfaceLanguage(it))
                }
            }
        }
    }
}