package pages.manageNotifications.ui

import csstype.Display
import csstype.FlexDirection
import csstype.px
import utils.types.CssStyle

val settingsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}