package online.interactiver.common.enums

enum class ELanguageLevel(val value: String, val en: String, val ru: String) {
    BEGINNER("Beginner", "Beginner", "Начинающий"),
    BASIC("Basic", "Basic", "Базовый"),
    ADVANCED("Advanced", "Advanced", "Продвинутый"),
    ANY("Any", "I don't know", "Не знаю");

    fun toString(nativeLanguage: String): String {
        return if (nativeLanguage == ELanguage.RUSSIAN.text) {
            ru
        } else {
            en
        }
    }
    companion object {
        fun fromString(value: String): ELanguageLevel? {
            return entries.find { it.value == value }
        }
    }
}
