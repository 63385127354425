package pages.manageNotifications.ui

import antd.Button
import antd.Spin
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.DuoProfileSettings.ui.page
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import shared.components.checkboxWithLabel.CheckboxWithLabel
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

val ManageNotifications = VFC {
    val navigate = useNavigate()
    val notifications = useSelector(selectNotifications)
    val dispatch = useAppDispatch()

    useEffectOnce {
        GlobalScope.launch {
            val notificationsResponse = getNotificationsSettingsRequest()
            if (notificationsResponse == null) {
                dispatch(OpenErrorModal("Error getting notifications"))
                return@launch
            }

            dispatch(SetNotifications(notificationsResponse))
        }
    }

    section {
        css(page)
        SolvedTasks{}
        if (notifications == null) {
            Spin {
                size = "large"
            }
        } else {
            div {
                css(settingsContainer)
                CheckboxWithLabel {
                    label = "Notification about course generation"
                    checked = notifications.enableCourseGenerationNotification
                    onChange = {
                        dispatch(SetEnableCourseGenerationNotification(it))
                        GlobalScope.launch {
                            val updated = updateNotificationsRequest(it)
                            if (updated) {
                                return@launch
                            }

                            dispatch(OpenErrorModal("Error updating setting"))
                            dispatch(SetEnableCourseGenerationNotification(!it))
                        }
                    }
                }
            }
        }

        Button {
            css(newTaskButton)
            onClick = {
                navigate(-1)
            }
            +"Back"
        }
    }
}
