package online.interactiver.common.autogeneration.taskLineContentFabric

import online.interactiver.common.autogeneration.taskLineContent.WordMatchingContent

class WordMatchingContentFabric(private val pairsCount: Int): TaskLineContentFabric<WordMatchingContent> {
    override fun fromRawTaskLine(taskLineContent: String, isOpenAiFormat: Boolean): WordMatchingContent {
        val rawPairs = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
        val wordMatchPairs = rawPairs.map {
            WordMatchingContent.WordMatch(
                it.substringBefore(" - ").removeSuffix(" "),
                it.substringAfter(" - ").removePrefix(" ")
            )
        }.take(pairsCount)
        return WordMatchingContent(wordMatchPairs)
    }

    override fun canBeBuiltFrom(taskLineContent: String, isOpenAiFormat: Boolean): Boolean {
        val pairs = taskLineContent.split(";").map { it.trim() }.filter { it.isNotBlank() }
        if (pairs.size != pairsCount) {
            return false
        }

        for (pair in pairs.take(pairsCount)) {
            if (!pair.contains(" - ") && !pair.contains("- ") && !pair.contains(" -")) {
                return false
            }
        }

        return true
    }
}
