package pages.studentDuo

import app.useAppDispatch
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.timers.clearInterval
import kotlinx.js.timers.setInterval
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import org.w3c.dom.get
import pages.languageAuto.getUserProfileRequest
import pages.payment.checkPayRequest
import react.router.dom.useSearchParams
import react.useEffect
import react.useEffectOnce
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams
import widgets.UserProfile.SetUserProfile

val useConfirmPayment = {
    val (searchParams, setSearchParams) = useSearchParams()
    val dispatch = useAppDispatch()

    val confirmPayment = searchParams.get("confirm_payment") == true.toString()

    useEffect(confirmPayment) {
        if (!confirmPayment) {
            return@useEffect
        }

        var checkedTimes = 0
        dispatch(StartModalLoading("Handling payment..."))
        val id = setInterval(
            {
                GlobalScope.launch {
                    val checkPaymentResponse = checkPayRequest()
                    checkedTimes += 1

                    if (checkPaymentResponse.data != true) {
                        if (checkedTimes < 11) {
                            return@launch
                        }

                        searchParams.delete("confirm_payment")
                        setSearchParams(searchParams)
                        dispatch(EndModalLoading())
                        dispatch(OpenErrorModal(
                            "If it was successful, please try to update page. Otherwise, please try again.",
                            "No information about your payment"
                        ))
                        return@launch
                    }

                    val userProfile = getUserProfileRequest().data ?: return@launch

                    searchParams.delete("confirm_payment")
                    setSearchParams(searchParams)
                    dispatch(EndModalLoading())
                    dispatch(SetUserProfile(userProfile))
                }
            },
            5000
        )

        cleanup {
            clearInterval(id)
        }
    }
}

val useDuoSearchParams = {
    val (searchParams) = useSearchParams()
    val dispatch = useAppDispatch()

    useEffectOnce {
        (searchParams.get(EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value)
            ?: localStorage["duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}"])?.let {
                dispatch(SetStudentDuoLanguageToLearn(it))
            localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}", it)
        }
        (searchParams.get(EStudentDuoSearchParams.INTERFACE_LANGUAGE.value)
            ?: localStorage["duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}"])?.let {
                dispatch(SetStudentDuoInterfaceLanguage(it))
            localStorage.setItem("duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}", it)
        }
        (searchParams.get(EStudentDuoSearchParams.LANGUAGE_LEVEL.value)
            ?: localStorage["duo_${EStudentDuoSearchParams.LANGUAGE_LEVEL.value}"])?.let {
            dispatch(SetStudentDuoLanguageLevel(it))
            localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_LEVEL.value}", it)
        }
    }
}

val useDuoCourses = {
    val dispatch = useAppDispatch()

    useEffectOnce {
        val coursesFromLocalStorage = try {
            val rawCoursesFromLocalStorage = localStorage.getItem("duo_courses") ?: throw Exception()
            Json.decodeFromString<List<DuoCourse>>(rawCoursesFromLocalStorage)
        } catch (e: Exception) {
            dispatch(SetDuoCoursesWereFetched(true))
            return@useEffectOnce
        }

        GlobalScope.launch {
            val ids = coursesFromLocalStorage.map { it.id }.toMutableList()
            val coursesResponse = getDuoCoursesRequest(ids).filter { it.states.isNotEmpty() }

            coursesResponse.forEach { courseResponse ->
                val courseFromLocalStorage = coursesFromLocalStorage.find { it.id == courseResponse.id }
                if (courseFromLocalStorage == null || courseFromLocalStorage.states.isEmpty()) {
                    courseResponse.states.first().state = EExerciseState.CURRENT.value
                    return@forEach
                }

                var i = 0
                var state: String? = courseFromLocalStorage.states.first().state
                while (state != null
                    && courseResponse.states.getOrNull(i)?.id == courseFromLocalStorage.states.getOrNull(i)?.id
                ) {
                    courseResponse.states.getOrNull(i)?.state = state
                    i += 1
                    state = courseFromLocalStorage.states.getOrNull(i)?.state
                }
            }

            localStorage.setItem("duo_courses", Json.encodeToString(coursesResponse))
            dispatch(SetDuoCourses(coursesResponse))
            dispatch(SetDuoCoursesWereFetched(true))
        }
    }
}
