package pages.manageNotifications.ui

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.notifications.Notifications
import online.interactiver.common.notifications.UpdateNotificationsRequest
import utils.getToken

suspend fun getNotificationsSettingsRequest(): Notifications? {
    return try {
        val response = jsonClient.get("api/notifications") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}

suspend fun updateNotificationsRequest(
    enableCourseGenerationNotification: Boolean
) : Boolean {
    return try {
        val response = jsonClient.put("api/notifications/update") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateNotificationsRequest(
                enableCourseGenerationNotification
            ))
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}