package pages.manageNotifications.ui

import online.interactiver.common.notifications.Notifications
import pages.studentDuo.StudentDuoStoreState
import redux.RAction

open class NotificationsAction : RAction

data class SetNotifications(val notifications: Notifications): NotificationsAction()
data class SetEnableCourseGenerationNotification(val enableCourseGenerationNotification: Boolean) : NotificationsAction()

fun notificationsReducer(state: Notifications?, action: NotificationsAction): Notifications? {
    return when (action) {
        is SetNotifications -> action.notifications
        is SetEnableCourseGenerationNotification -> state?.copy(
            enableCourseGenerationNotification = action.enableCourseGenerationNotification
        )
        else -> state
    }
}

val selectNotifications = { state: StudentDuoStoreState ->
    state.notifications
}
