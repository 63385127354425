package pages.DuoProfileSettings.ui

import antd.Button
import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.router.useNavigate
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

val DuoProfileSettings = VFC {
    val navigate = useNavigate()

    section {
        css(page)
        SolvedTasks {}
        div {
            css(buttonsContainer)
            Button {
                css(newTaskButton)
                disabled = true
                +"Manage my subscription"
            }
            Button {
                css(newTaskButton)
                disabled = true
                +"Profile"
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate("/duo/manage-notifications")
                }
                +"Manage notification"
            }
        }
        Button {
            css(newTaskButton)
            onClick = {
                navigate(-1)
            }
            +"Back"
        }
    }
}